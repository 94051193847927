import React from "react";
import {
  NavigationContainer,
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import Dash from "./src/pages/Dash";
import { AppRegistry } from "react-native";
import { PaperProvider, MD3LightTheme, MD3DarkTheme } from "react-native-paper";
import { expo } from "./app.json";

const App = () => {
  const Stack = createStackNavigator();
  return (
    <PaperProvider theme={MD3DarkTheme}>
      <NavigationContainer theme={NavigationDarkTheme}>
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen name="Dash" component={Dash} />
        </Stack.Navigator>
      </NavigationContainer>
    </PaperProvider>
  );
};
export default App;
AppRegistry.registerComponent(expo.name, () => App);
