import { StatusBar } from "expo-status-bar";
import { StyleSheet, View } from "react-native";
import { DataTable, Text } from "react-native-paper";
import React, { useState, useEffect } from "react";

export default function Dash() {
  const [page, setPage] = React.useState(0);
  const [numberOfItemsPerPageList] = React.useState([2, 3, 4]);
  const [itemsPerPage, onItemsPerPageChange] = React.useState(
    numberOfItemsPerPageList[0]
  );
  const [items] = React.useState([
    {
      key: 1,
      name: "Knife 1",
      cost: 356,
      num_sold: 16,
    },
    {
      key: 2,
      name: "Knife 2",
      cost: 262,
      num_sold: 16,
    },
    {
      key: 3,
      name: "Knife 4",
      cost: 159,
      num_sold: 6,
    },
    {
      key: 4,
      name: "Knife 5",
      cost: 305,
      num_sold: 4,
    },
  ]);

  const from = page * itemsPerPage;
  const to = Math.min((page + 1) * itemsPerPage, items.length);

  React.useEffect(() => {
    setPage(0);
  }, [itemsPerPage]);
  return (
    <View style={styles.container}>
      <Text>Zac says Hi!</Text>
      <Text style={{ margin: 20 }}>Contact List: </Text>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>Name</DataTable.Title>
          <DataTable.Title>Email</DataTable.Title>
          <DataTable.Title numeric>Age</DataTable.Title>
          <DataTable.Title numeric>Units Sold</DataTable.Title>
        </DataTable.Header>

        <DataTable.Row>
          <DataTable.Cell>John</DataTable.Cell>
          <DataTable.Cell>john@kindacode.com</DataTable.Cell>
          <DataTable.Cell numeric>33</DataTable.Cell>
          <DataTable.Cell numeric>45</DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row>
          <DataTable.Cell>Bob</DataTable.Cell>
          <DataTable.Cell>test@test.com</DataTable.Cell>
          <DataTable.Cell numeric>105</DataTable.Cell>
          <DataTable.Cell numeric>55</DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row>
          <DataTable.Cell>Mei</DataTable.Cell>
          <DataTable.Cell>mei@kindacode.com</DataTable.Cell>
          <DataTable.Cell numeric>23</DataTable.Cell>
          <DataTable.Cell numeric>20</DataTable.Cell>
        </DataTable.Row>
      </DataTable>
      <Text style={{ margin: 20 }}>Inventory Table:</Text>
      <DataTable>
        <DataTable.Header>
          <DataTable.Title>Knives</DataTable.Title>
          <DataTable.Title numeric>Cost</DataTable.Title>
          <DataTable.Title numeric>Number Sold</DataTable.Title>
        </DataTable.Header>

        {items.slice(from, to).map((item) => (
          <DataTable.Row key={item.key}>
            <DataTable.Cell>{item.name}</DataTable.Cell>
            <DataTable.Cell numeric sortDirection="descending">
              {item.cost}
            </DataTable.Cell>
            <DataTable.Cell numeric>{item.num_sold}</DataTable.Cell>
          </DataTable.Row>
        ))}

        <DataTable.Pagination
          page={page}
          numberOfPages={Math.ceil(items.length / itemsPerPage)}
          onPageChange={(page) => setPage(page)}
          label={`${from + 1}-${to} of ${items.length}`}
          numberOfItemsPerPageList={numberOfItemsPerPageList}
          numberOfItemsPerPage={itemsPerPage}
          onItemsPerPageChange={onItemsPerPageChange}
          showFastPaginationControls
          selectPageDropdownLabel={"Rows per page"}
        />
      </DataTable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 30,
  },
});
